import React from 'react';
import '../../css/main.css';
import moment from 'moment';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

// const queryService = 'http://localhost:8080/graphql/';
const queryService = 'https://backend.demo.parqr.app:8443/graphql/';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
}

function CardSection() {
    return <label>
        Card Details
        <CardElement options={CARD_ELEMENT_OPTIONS} />

    </label>
}

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const startPayment = `mutation startPayment($amount: Int!, $spotName: ID!, $lotName: ID!){
            startPayment(input: {amount: $amount, spot: {spotName: $spotName, lotName: $lotName}} ) {
                success
                message
                secret
            }
        }`;

        const completePayment = `mutation completePayment($transactionId: String!){
            completePayment(input: {transactionId: $transactionId}) {
                success
                message
            }
        }`

        var secret = "";

        const fetchResponse = fetch(queryService, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                query: startPayment,
                variables: { amount: Math.round(props.amount), spotName: props.spotName, lotName: props.lotName },
            })
        }).then(r => r.json()).then(data => {
            let res = data.data.startPayment;
            if (res.success == true) {
                secret = res.secret;
            } else {
                console.log(res.message);
            }
        });

        await fetchResponse;
        console.log(secret);
        if (secret === "") {
            return;
        }

        const result = await stripe.confirmCardPayment(secret, {
            payment_method: {
                card: elements.getElement(CardElement),
            }
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                console.log("Payment Success");
                const fetchResponse = fetch(queryService, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({
                        query: completePayment,
                        variables: { transactionId: result.paymentIntent.id },
                    })
                }).then(r => r.json()).then(data => {
                    let res = data.data.completePayment;
                    if (res.success == true) {
                        console.log("Lot time update success");
                        props.onPayment();
                    } else {
                        console.log(res.message);
                    }
                });
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
            }
        }
    };

    return (
        // TODO: disable while transacting
        <form onSubmit={handleSubmit}>
            <CardSection />
            <button disabled={!stripe}>Confirm Payment</button>
        </form>
    );
}