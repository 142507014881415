import React, { useState, useEffect } from 'react';
import moment from 'moment';
// TODO: better relative imports
import { Header } from '../../../../../../components/header.js';
import { AddTime } from '../../../../../../components/parker/add-time.js';
import { ParkingMeter } from '../../../../../../components/parker/parking-meter.js';
import '../../../../../../css/main.css';

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import PayButton from '../../../../../../components/payment/PayButton.js';

const stripePromise = loadStripe('pk_test_51JZT7fLBm4Ihz0NC9q5UNwoiPHEXAVSI1BqpVSOmlKgiquacCqKcpEV888D0RJU88AjGzO0lP9tC7lOKfInIVOQE00vFS4dAz5');
// const queryService = 'http://localhost:8080/graphql/';
const queryService = 'https://backend.demo.parqr.app:8443/graphql/';

const SpotPage = (props) => {

    const { lotName, spotName } = props.params;
    // query vals
    const [addTime, setAddTime] = useState(moment.duration(0));
    const [rate, setRate] = useState(0);
    const [lastLoad, setLastLoad] = useState(moment());
    const [expiry, setExpiry] = useState(moment());

    // computed vals
    const spotQuery = `query spotQuery($lotName: ID!, $spotName: ID!) {
        spot(input: {lotName: $lotName, spotName: $spotName}) {
            endTime
            lot {
                rate
                timeUnit
                minCost
            }
        }
    }`;

    // sets initial state on page load or after success
    useEffect(() => {
        return fetch(queryService, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                query: spotQuery,
                variables: { lotName: lotName, spotName: spotName },
            })
        })
            .then(r => r.json())
            .then(data => {
                console.log(data);
                // TODO: validation on id
                setRate(data.data.spot.lot.rate);
                setAddTime(moment.duration(data.data.spot.lot.timeUnit, 'minutes'));
                setExpiry(moment(data.data.spot.endTime));
            })
    }, [lastLoad])


    return (
        <Elements stripe={stripePromise}>
            <Header />
            <ParkingMeter
                lotName={props.lotName}
                spotName={props.spotName}
                expiry={expiry}
            />
            <AddTime
                lotName={props.lotName}
                spotName={props.spotName}
                rate={rate}
                addTime={addTime}
                expiry={expiry}
                onUpdate={() => { setLastLoad(moment()) }}
            />
        </Elements>
    );
}

export default SpotPage;
