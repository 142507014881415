import React from 'react';
import moment from 'moment';
import '../../css/main.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../payment/CheckoutForm.js'
import CheckoutForm from '../payment/CheckoutForm.js';

// max time that can be added to the meter in minutes
const maxAddedTime = 360;

const stripePromise = loadStripe("pk_test_51JZT7fLBm4Ihz0NC9q5UNwoiPHEXAVSI1BqpVSOmlKgiquacCqKcpEV888D0RJU88AjGzO0lP9tC7lOKfInIVOQE00vFS4dAz5");

class AddTime extends React.Component {
    constructor(props) {
        super(props);
        this.resetState = this.resetState.bind(this);
        this.confirm = this.confirm.bind(this);
        this.incrementTime = this.incrementTime.bind(this);
        this.decrementTime = this.decrementTime.bind(this);
        this.onUpdate = this.props.onUpdate.bind(this);

        this.state = {
            totalCost: 0.0, newExpiry: this.props.expiry.clone(),
            additionalDuration: moment.duration(0),
            decrementButton: (<button disabled>-</button>),
            incrementButton: <button onClick={this.incrementTime}>+</button>,
            paying: false
        };
        this.priceIncrement = Math.round(this.props.rate * (this.props.addTime.asMinutes() / 60));
    }

    static getDerivedStateFromProps(props, state) {
        if (moment.duration(props.expiry.diff(moment())) > 0 && state.additionalDuration <= 0) {
            return { newExpiry: props.expiry.clone() };
        } else if (moment.duration(state.newExpiry.diff(moment())) < 0) {
            return { newExpiry: moment() };
        } else {
            return null;
        }
    }

    componentDidUpdate() {
        this.priceIncrement = Math.round(this.props.rate * (this.props.addTime.asMinutes() / 60));
    }

    resetState() {
        this.onUpdate();
        this.setState({
            totalCost: 0.0,
            newExpiry: this.props.expiry,
            additionalDuration: moment.duration(0),
            decrementButton: (<button disabled>-</button>),
            incrementButton: <button onClick={this.incrementTime}>+</button>,
            paying: false
        });
    }

    incrementTime() {
        this.setState({
            totalCost: this.state.totalCost + this.priceIncrement,
            newExpiry: this.state.newExpiry.clone().add(this.props.addTime),
            additionalDuration: this.state.additionalDuration.add(this.props.addTime),
            decrementButton: <button onClick={this.decrementTime}>-</button>
        });

        if (moment.duration(this.state.newExpiry.diff(moment())).asMinutes() >= maxAddedTime) {
            this.setState({ incrementButton: <button disabled>+</button> });
        }
    }

    decrementTime() {
        if (this.state.additionalDuration.asMinutes() > 0) {
            this.setState({
                totalCost: this.state.totalCost - this.priceIncrement,
                newExpiry: this.state.newExpiry.subtract(this.props.addTime),
                additionalDuration: this.state.additionalDuration.subtract(this.props.addTime),
                incrementButton: <button onClick={this.incrementTime}>+</button>
            });
        }
        if (this.state.additionalDuration.asMinutes() <= 0) {
            this.setState({ decrementButton: <button disabled>-</button> });
        }
    }

    confirm() {
        // show checkout form
        this.setState({
            paying: true
        });
    }

    render() {
        if (this.state.paying) {
            return (
                <Elements stripe={stripePromise}>
                    <CheckoutForm amount={this.state.totalCost}
                        spotName={this.props.spotName}
                        lotName={this.props.lotName}
                        onPayment={this.resetState}
                    />
                </Elements>
            );
        } else {
            return (
                <div>
                    <section className="add-time">
                        <div className="container">
                            <div className="incrementor">
                                <div className="subtract-button">
                                    {this.state.decrementButton}
                                </div>
                                <div className="subtotal">
                                    <div className="countdown-display">
                                        <span>{this.state.additionalDuration.asMinutes()} minutes</span>
                                    </div>
                                    <div className="expiry-display">
                                        <span>Expires {this.state.newExpiry.format("HH:mm")}</span>
                                    </div>
                                </div>
                                <div className="add-button">
                                    {this.state.incrementButton}
                                </div>
                            </div>
                            <div className="confirm-button">
                                <button onClick={this.confirm}>
                                    <div className="confirm-price">
                                        ${this.state.totalCost / 100}
                                    </div>
                                    <div className="confirm-message">
                                        Tap to Confirm
                                    </div>
                                </button>
                            </div>
                        </div>
                    </section>
                </div >
            );
        }

    }
}

export { AddTime }